<template>
		<div style="padding:50px 15px 0 15px;">
			<div style="text-align: center;">
				<van-icon name="passed" color="#1989fa" size="100"/>
				<h4>注册成功</h4>
			</div>
			<p style="text-align: left;text-indent:30px;line-height: 30px;">
				您已成功注册招投研究院评标专家系统账号，请及时在电脑端登录账号完善专家信息并提交审核。
			</p>
			<div style="margin-top: 40px;">
				<span style="font-size: 14px;vertical-align: middle;">
					专家信息管理系统:{{url}}
				</span>
				<van-button type="info" size="mini" style="vertical-align: middle;margin-left: 10px;" @click="copy()">
					复制链接
				</van-button>
			</div>
			<input style="position: fixed;top:-100px;left:0;opacity: 0;z-index: -10;width: 1px;" :value="url" id="copyValue">
		</div>
		
</template>

<script>
	import Vue from 'vue';
	import { Icon,Button,Toast} from 'vant';
	Vue.use(Icon);
	Vue.use(Button);
	Vue.use(Toast);
export default {
	name:'registerSuccess',
	data(){
		return {
			url:'http://expert.zaototech.cn/#/',
			show:false
		}
	},
	methods:{
		copy(){
			document.getElementById('copyValue').select();
			document.execCommand("copy");
			Toast.success('复制成功');
		}
	}
}
</script>

<style>
</style>
